import React from 'react';
import pvc_stabilizer from '../../assets/images/pvc_stabilizer.jpg';
import pvc_pipe_stabilizer from '../../assets/images/pvc_pipe_stabilizer.jpg';
import pvc_individual_stabilizer from '../../assets/images/pvc_individual_stabilizer.jpg';
import pvc_film_sheet_stabilizer from '../../assets/images/pvc_film_sheet_stabilizer.jpg';
import pvc_footwear_stabilizer from '../../assets/images/pvc_footwear_stabilizer.jpg';

function PVCStabilizer() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={pvc_stabilizer} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">PVC Stabilizer</h5>
                <p className="text-muted mb-0">Stabilizers are added to PVC to allow its processing and to improve its resistance especially in outdoor applications, weathering and heat ageing and have an important influence on the physical properties of PVC finished articles.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>PVC Pipe Stabilizer</div>
              <div className="card-body">
                <div className="card-text"></div>
                <img className="card-img-bottom" src={pvc_pipe_stabilizer} alt="" style={{ width: '160px', height: '150px' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>PVC Individual Stabilizer</div>
              <div className="card-body">
                <div className="card-text"></div>
                <img className="card-img-bottom" src={pvc_individual_stabilizer} alt="" style={{ width: '160px', height: '150px' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>PVC Film Sheets Stabilizer</div>
              <div className="card-body">
                <div className="card-text"></div>
                <img className="card-img-bottom" src={pvc_film_sheet_stabilizer} alt="" style={{ width: '160px', height: '150px' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>PVC Footwear Stabilizer</div>
              <div className="card-body">
                <div className="card-text"></div>
                <img className="card-img-bottom" src={pvc_footwear_stabilizer} alt="" style={{ width: '160px', height: '150px' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PVCStabilizer;
