import React from 'react';
import ContactMap from './ContactMap';

function Contact() {
  return (
    <section className="section" id="contact">
        <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="title text-center mb-5">
                  <p className="text-uppercase text-muted mb-2 f-13 subtitle">Contact Us</p>
                  <h3>We will hear you!</h3>
                  <p className="text-muted mt-3">You can fill this form below and send your query to us, we will surely reply you soon!</p>
                </div>
              </div>
            </div>
        <div className="row justify-content-center">
          <div className="col-lg-5" style={{ borderRight: '1px solid #a0a0a0' }}>
            <div className="custom-form">
              <div id="message"></div>
              <form method="post" action="/" onSubmit={(e) => e.preventDefault()}>
                <input type="hidden" name="_token" value="" />
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <input name="name" id="name" type="text" className="form-control" placeholder="Your Name..." />
                    </div>
                    <div className="form-group mb-4">
                      <input name="email" id="email" type="email" className="form-control" placeholder="Your Email..."/>
                    </div>
                    <div className="form-group mb-4">
                      <input name="subject" id="subject" type="text" className="form-control" placeholder="Your Subject..." />
                    </div>
                    <div className="form-group">
                      <textarea name="message" id="message" rows="6" className="form-control" maxLength="350" required="" placeholder="Your Message..."></textarea>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 text-right">
                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-custom" placeholder="Send Message" />
                    <div id="simple-msg"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-5">
            <div className="row">
              <div className="col-lg-12" style={{ fontSize: '20px' }}>
                <i className="mdi mdi-phone text-success"></i> Contact No. +971503750714
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" style={{ fontSize: '20px' }}>
                <i className="mdi mdi-email text-success"></i> alwasayindustrialchemicals@gmail.com
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12" style={{ fontSize: '20px' }}>
                <i className="mdi mdi-map-marker text-success"></i> M-20 Bin Thani Plaza Naif Dera Dubai

              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ContactMap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
