import React from 'react';
import dyes from '../../assets/images/dyes.jpg';


function Dyes() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
              <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={dyes} alt="" height="100"/>
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Dyes</h5>
                <p className="text-muted mb-0">A dye is a colored substance that has an affinity to the substrate to which it is being applied. The dye is generally applied in an aqueous solution, and may require a mordant to improve the fastness of the dye on the fiber</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>reactive Dyes</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Acid Dyes</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Direct Dyes</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Basic Dyes</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Solvent Dyes</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dyes;
