import React from 'react';
import organic_pigments from '../assets/images/organic_pigments.jpg'
import inorganic_pigments from '../assets/images/inorganic_pigments.jpg'
import pigment_paste from '../assets/images/pigment_paste.jpg'
import white_pigment from '../assets/images/white_pigment.jpg'
import black_pigment from '../assets/images/black_pigment.jpg'
import pvc_stabilizer from '../assets/images/pvc_stabilizer.jpg'
import pu_adhesives_lacquers from '../assets/images/pu_adhesives_lacquers.jpg'
import optical_brighters from '../assets/images/optical_brighters.jpg'
import food_colors from '../assets/images/food_colors.jpg'
import dyes from '../assets/images/dyes.jpg'
import resin from '../assets/images/resin.jpg'
import paint_driers from '../assets/images/paint_driers.jpg'
import { Link } from 'react-router-dom';

function Products() {
  return (
    <section className="section bg-light" id="services">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="title text-center mb-5">
              <p className="text-uppercase text-muted mb-2 f-13 subtitle">Products</p>
              <h3>What we deal in</h3>
              <p className="text-muted mt-3">We develop quality products with perfect measurement</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Link to="/products/organic-pigments">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={organic_pigments} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Organic Pigments</h5>
                  <p className="text-muted mb-0">Organic pigment refers to colored material made of organic compound with pigment properties. Common types include azo pigments, lake pigments, phthalocyanine pigments and quinacridone pigments</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/inorganic-pigments">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={inorganic_pigments} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Inorganic Pigments</h5>
                  <p className="text-muted mb-0">Those Pigments that are made up of mineral compounds are known as to be Inorganic Pigments. These minerals are mainly oxides, sulphides of one or more than one metals. To impart colors in different compounds, inorganic pigments are applied</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/pigment-paste">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={pigment_paste} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Pigment Paste</h5>
                  <p className="text-muted mb-0">The pigment pastes are aqueous based and contain the dispersing agent, humectants (to prevent evaporation and drying out)</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Link to="/products/white-pigment">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={white_pigment} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">White Pigment</h5>
                  <p className="text-muted mb-0">Pigments that contribute light-scattering properties to coatings are generally known as white, or hiding, pigments. They act by scattering all wavelengths of light, owing to their relatively high refractive index, so that they are perceived as white by the human eye.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/black-pigment">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={black_pigment} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Black Pigment</h5>
                  <p className="text-muted mb-0">Black pigments have been around since human kind discovered they could take a burnt stick and make a mark. Some may argue as to whether it is the oldest pigment but it's safe to say it's probably one of the first</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/pvc-stabilizer">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={pvc_stabilizer} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">PVC Stabilizer</h5>
                  <p className="text-muted mb-0">Stabilizers are added to PVC to allow its processing and to improve its resistance especially in outdoor applications, weathering and heat ageing and have an important influence on the physical properties of PVC finished articles.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Link to="/products/pu-adhesives-and-lacquers">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={pu_adhesives_lacquers} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">PU Adhesives &amp; Lacquers</h5>
                  <p className="text-muted mb-0">First, polyurethane, PU for short or sometimes PUR, is not a single material with a fixed composition. Rather it is a range of chemicals sharing similar chemistry. It is a polymer composed of units of organic chains joined by urethane or carbamate links.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/paint-driers">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={paint_driers} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Paint Driers</h5>
                  <p className="text-muted mb-0">Driers are materials that promote or accelerate the curing or hardening of film formers that contain oxidizable or drying oil components. Air drying is the formation of a solid film at ambient temperature by oxidation processes from an applied liquid coating.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/optical-brighters">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={optical_brighters} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Optical Brightners</h5>
                  <p className="text-muted mb-0">Optical Brighters are chemical compounds that absorb light in the ultraviolet and violet region of the electromagnetic spectrum, and re-emit light in the blue region by fluorescence.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <Link to="/products/food-colors">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={food_colors} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Food Colors</h5>
                  <p className="text-muted mb-0">Food coloring is used both in commercial food production and in domestic cooking. Food colorants are also used in a variety of non-food applications including cosmetics, pharmaceuticals, home craft projects, and medical devices</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/dyes">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={dyes} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Dyes</h5>
                  <p className="text-muted mb-0">A dye is a colored substance that has an affinity to the substrate to which it is being applied. The dye is generally applied in an aqueous solution, and may require a mordant to improve the fastness of the dye on the fiber</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/products/resin">
              <div className="busi-services-box p-4 bg-white mt-5">
                <div className="busi-services-icon">
                  <i className="text-custom">
                    <img src={resin} alt="" height="100" />
                  </i>
                </div>
                <div className="busi-services-desc">
                  <h5 className="mb-4 f-18">Resins</h5>
                  <p className="text-muted mb-0">A solid or liquid synthetic organic polymer used as the basis of plastics, adhesives, varnishes, or other products.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
