import React from 'react';
import optical_brighters from '../../assets/images/optical_brighters.jpg';

function OpticalBrighter() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={optical_brighters} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Optical Brightners</h5>
                <p className="text-muted mb-0">Optical Brighters are chemical compounds that absorb light in the ultraviolet and violet region of the electromagnetic spectrum, and re-emit light in the blue region by fluorescence.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>OB</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>OB 1</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OpticalBrighter;
