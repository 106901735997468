import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import Contact from './components/Contact';
import OrganicPigment from './components/products/OrganicPigment';
import InorganicPigment from './components/products/InorganicPigment';
import PigmentPaste from './components/products/PigmentPaste';
import WhitePigment from './components/products/WhitePigment';
import BlackPigment from './components/products/BlackPigment';
import PVCStabilizer from './components/products/PVCStabilizer';
import PuAdhesivesLacquers from './components/products/PuAdhesivesLacquers';
import PaintDriers from './components/products/PaintDriers';
import OpticalBrighter from './components/products/OpticalBrighter';
import FoodColor from './components/products/FoodColor';
import Dyes from './components/products/Dyes';
import Resin from './components/products/Resin';
import './index.css';
import './magnific-popup.css'
import './default.css'
import './home.css'
import './materialdesignicons.min.css'

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/products" element={<Products/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/products/organic-pigments" element={<OrganicPigment/>}/>
          <Route exact path="/products/inorganic-pigments" element={<InorganicPigment/>}/>
          <Route exact path="/products/pigment-paste" element={<PigmentPaste/>}/>
          <Route exact path="/products/white-pigment" element={<WhitePigment/>}/>
          <Route exact path="/products/black-pigment" element={<BlackPigment/>}/>
          <Route exact path="/products/pvc-stabilizer" element={<PVCStabilizer/>}/>
          <Route exact path="/products/pu-adhesives-and-lacquers" element={<PuAdhesivesLacquers/>}/>
          <Route exact path="/products/paint-driers" element={<PaintDriers/>}/>
          <Route exact path="/products/optical-brighters" element={<OpticalBrighter/>}/>
          <Route exact path="/products/food-colors" element={<FoodColor/>}/>
          <Route exact path="/products/resin" element={<Resin/>}/>
          <Route exact path="/products/dyes" element={<Dyes/>}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
