import React from 'react';
import pu_adhesives_lacquers from '../../assets/images/pu_adhesives_lacquers.jpg';

function PuAdhesivesLacquers() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={pu_adhesives_lacquers} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">PU Adhesives &amp; Lacquers</h5>
                <p className="text-muted mb-0">First, polyurethane, PU for short or sometimes PUR, is not a single material with a fixed composition. Rather it is a range of chemicals sharing similar chemistry. It is a polymer composed of units of organic chains joined by urethane or carbamate links.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">PU Adhesives</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-success">
                      <tr>
                        <th>Footwear</th>
                        <th>Packaging</th>
                        <th>Lamination</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Adhesives</td>
                        <td>Adhesives</td>
                        <td>Adhesives</td>
                      </tr>
                      <tr>
                        <td colspan="3">Primers</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">PU Lacquers</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="table-primary">
                      <tr align="center">
                        <th colspan="2">Leather</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>PU Soft</td>
                        <td>PU Medium</td>
                      </tr>
                      <tr>
                        <td>PU Hard</td>
                        <td>PU Special Effect</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PuAdhesivesLacquers;
