import React from 'react';
import organic_pigments from '../../assets/images/organic_pigments.jpg';


function OrganicPigment() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={organic_pigments} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Organic Pigments</h5>
                <p className="text-muted mb-0">Organic pigment refers to colored material made of organic compound with pigment properties. Common types include azo pigments, lake pigments, phthalocyanine pigments and quinacridone pigments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Yellow Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#FFC300' }}>
                        <td>Yellow 1</td>
                        <td>Yellow 3</td>
                        <td>Yellow 12</td>
                      </tr>
                      <tr style={{ backgroundColor: '#FFC300' }}>
                        <td>Yellow 13</td>
                        <td>Yellow 14</td>
                        <td>Yellow 74</td>
                      </tr>
                      <tr style={{ backgroundColor: '#FFC300' }}>
                        <td colSpan="3">Yellow 83</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Blue Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#3399FF' }}>
                        <td>Blue 15:0</td>
                        <td>Blue 15:1</td>
                        <td>Blue 15:2</td>
                      </tr>
                      <tr style={{ backgroundColor: '#3399FF' }}>
                        <td>Blue 15:3</td>
                        <td>Blue 15:4</td>
                        <td>Blue 27</td>
                      </tr>
                      <tr style={{ backgroundColor: '#3399FF' }}>
                        <td colSpan="3">Ultramarine Blue</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Green Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#45E076' }}>
                        <td>Green 7</td>
                        <td colSpan="2">Green 34</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Red Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#FF5733' }}>
                        <td>Red 3</td>
                        <td>Red 48:2</td>
                        <td>Red 53:1</td>
                      </tr>
                      <tr style={{ backgroundColor: '#FF5733' }}>
                        <td>Red 57:1</td>
                        <td>Red 63:1</td>
                        <td>Red 122</td>
                      </tr>
                      <tr style={{ backgroundColor: '#FF5733' }}>
                        <td colSpan="3">Red 170</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Orange Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#FF8000' }}>
                        <td>Orange 5</td>
                        <td>Orange 13</td>
                        <td>Orange 34</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">Violet Pigment</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr style={{ backgroundColor: '#7F00FF' }}>
                        <td>Voilet 3</td>
                        <td>Voilet 19</td>
                        <td>Voilet 23</td>
                      </tr>
                      <tr style={{ backgroundColor: '#7F00FF' }}>
                        <td colSpan="3">Voilet 27</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OrganicPigment;
