import React from 'react';
import inorganic_pigments from '../../assets/images/inorganic_pigments.jpg';

function InorganicPigment() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={inorganic_pigments} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Inorganic Pigments</h5>
                <p className="text-muted mb-0">Those Pigments that are made up of mineral compounds are known as to be Inorganic Pigments. These minerals are mainly oxides, sulphides of one or more than one metals. To impart colors in different compounds, inorganic pigments are applied</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: '#FFC80F'}}>Middle Chrome</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: '#FFE71F'}}>Lemon Chrome</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: '#FFF72F'}}>Primerose Chrome</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: '#DF3810'}}>Scarlet Chrome</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: '#FFFD07'}}>Light Yellow Chrome</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white'}}>Chromium Pigments</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InorganicPigment;
