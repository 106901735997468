import React from 'react';
import paint_driers from '../../assets/images/paint_driers.jpg';


function PaintDriers() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
              <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={paint_driers} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Pigment Paste</h5>
                <p className="text-muted mb-0">The pigment pastes are aqueous based and contain the dispersing agent, humectants (to prevent evaporation and drying out)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container"></div>
    </section>
  );
}

export default PaintDriers;
