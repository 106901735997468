import React from 'react';
import black_pigment from '../../assets/images/black_pigment.jpg';

function BlackPigment() {
  return (
    <section className="section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="busi-services-box p-4 bg-white mt-5">
            <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i className="text-custom">
                  <img src={black_pigment} alt="" height="100" />
                </i>
              </div>
              <div className="busi-services-desc">
                <h5 className="mb-4 f-18">Black Pigment</h5>
                <p className="text-muted mb-0">Black pigments have been around since human kind discovered they could take a burnt stick and make a mark. Some may argue as to whether it is the oldest pigment but it's safe to say it's probably one of the first</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Paints and Coatings</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Leather</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Masterbatches</div>
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header" style={{ background: 'white' }}>Rubber</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlackPigment;
