import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import React from 'react';

const Header = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light nav-sticky">
        <div className="container">
          <a className="logo text-uppercase" href="/">
            <img src={logo} alt="" className="logo-dark" height="50" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <i className="mdi mdi-menu text-white"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
              <li className="nav-item ">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item ">
                <Link to="/about" className="nav-link">About</Link>
              </li>
              <li className="nav-item ">
                <Link to="/products" className="nav-link">Products</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
