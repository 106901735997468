
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 offset-4">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">Company</p>
                  <ul className="list-unstyled footer-list-menu">
                    <li><Link to="/about" className="nav-link">About Us</Link></li>
                    <li><Link to="/products" className="nav-link">Products</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <p className="text-white mb-4 footer-list-title f-17">Help center</p>
                  <ul className="list-unstyled footer-list-menu">
                    <li><Link to="/contact" className="nav-link">Contact Us</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-white">
                  <h3 className="mb-4 footer-list-title f-17">Find Us</h3>
                  <ul className="footer-icons list-inline mb-4">
                    <li className="list-inline-item"><a href="/" className=""><i className="mdi mdi-facebook"></i></a></li>
                    <li className="list-inline-item"><a href="/" className=""><i className="mdi mdi-twitter"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
