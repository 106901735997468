import ceo from '../assets/images/ceo.jpg'
import director from '../assets/images/director.jpg'
import React from 'react';

function About() {
  return (
    <section className="section" id="about">
      <div className="container">
        <div className="row justify-content-center mb-3">
          <div className="col-lg-10">
            <div className="about-content text-center">
              <div className="busi-team-box bg-white mt-4">
                <div className="busi-team-img">
                  <img src={ceo} alt="" className="img-fluid mx-auto d-block rounded-circle" />
                </div>
                <div className="team-content text-center mt-3">
                  <h5 className="team-name f-17 text-dark">Tariq Mahmood</h5>
                  <p className="text-custom f-14">CEO/Founder</p>
                </div>
                <div className="busi-testi-box bg-white p-4 mt-4">
                <h6 className="mb-1">Message from CEO</h6>
                <p className="text-muted mb-0 pt-2 f-14">Since last decade, alwasay has been a group of skilled &amp; professionals who are marketing different raw materials through courtesy of their trading and state of the art quality. We strongly believe in developing friendship &amp; business relations with our National and International clients. As we know our customers are our valuable chattels, business partners and good-will.</p>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mb-3">
          <div className="col-lg-10">
            <div className="about-content text-center">
              <div className="busi-team-box bg-white mt-4">
                <div className="busi-team-img">
                  <img src={director} alt="" className="img-fluid mx-auto d-block rounded-circle" />
                </div>
                <div className="team-content text-center mt-3">
                  <h5 className="team-name f-17 text-dark">Ahmed Siddiqui</h5>
                  <p className="text-custom f-14">Director</p>
                </div>
              </div>
              <div className="busi-testi-box bg-white p-4 mt-4">
                <h6 className="mb-1">Message from Director</h6>
                <p className="text-muted mb-0 pt-2 f-14">At Alwasay, our mission is simple: to provide exceptional quality and service in every interaction. Your satisfaction is our priority, and we are committed to strengthening our bonds through trust and reliability.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-lg-10">
            <div className="title text-center mb-3">
              <h3>MISSION</h3>
            </div>
            <div className="busi-testi-box bg-white p-4 mt-2">
              <p className="text-muted mb-0 pt-2 f-14">Our mission is to lead the industry to the front with complete commitment and dedication on standards, quality and customer satisfaction.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
