import slider1 from '../assets/images/slider1.jpg';
import slider2 from '../assets/images/slider2.jpg';
import slider3 from '../assets/images/slider3.jpg';
import { Link } from 'react-router-dom';

import React from 'react';

function Home() {
  return (
    <section className="home-slider" id="home">
      <div className="container-fluid">
        <div className="row">
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active" style={{ background: `url(${slider1})` }}>
                  <div className="bg-overlay"></div>
                  <div className="home-center">
                    <div className="home-desc-center">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="busi-home-title text-white text-center">
                              <h2 className="animated fadeInDownBig animation-delay-1 mb-4">Alwasay Basic industrial Chemicals</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" style={{ background: `url(${slider2})` }}>
                  <div className="bg-overlay"></div>
                  <div className="home-center">
                    <div className="home-desc-center">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="busi-home-title text-white text-center">
                              <h2 className="animated fadeInDownBig animation-delay-1 mb-4">The World of Colors</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item" style={{ background: `url(${slider3})` }}>
                  <div className="bg-overlay"></div>
                  <div className="home-center">
                    <div className="home-desc-center">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="busi-home-title text-white text-center">
                              <h2 className="animated fadeInDownBig animation-delay-1 mb-4">Supplying the world with high quality chemicals</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
              </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="about-content text-center">
              <p className="text-muted mt-3">Since last decade, alwasay has been a group of skilled &amp; professionals who are marketing  different raw materials through courtesy of their trading and state of the art quality. We strongly believe in developing friendship &amp; business relations with our National and International clients. As we know our customers are our valuable chattels, business partners and good-will.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="about-content text-center">
              <h4 className="mx-auto mb-4 mt-3">VISION</h4>
              <p className="text-muted">We will work together to define and understand every process performed by the organization and continuously strive to improve the quality at different stages of supply. Our aim is to grow by providing standard and superior chemical products and support services that are always a step ahead, eco-friendly, and loved by our customers. We will give our customers every reason to rely on us and will achieve our vision by developing win-win partnerships with our clients and open communications with our all stake holders including associates, customers and suppliers.</p>
              <div className="pt-4 pb-4">
                <Link to="/products" className="btn btn-custom animated fadeInUpBig animation-delay-12">Explore Products</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
