import React from 'react';
import white_pigment from '../../assets/images/white_pigment.jpg';

function WhitePigment() {
  return (
    <section class="section bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="busi-services-box p-4 bg-white mt-5">
              <div class="busi-services-icon" style={{ width: '150px', height: '160px' }}>
                <i class="text-custom">
                  <img src={white_pigment} alt="" height="100" />
                </i>
              </div>
              <div class="busi-services-desc">
                <h5 class="mb-4 f-18">White Pigment</h5>
                <p class="text-muted mb-0">Pigments that contribute light-scattering properties to coatings are generally known as white, or hiding, pigments. They act by scattering all wavelengths of light, owing to their relatively high refractive index, so that they are perceived as white by the human eye.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row pt-2">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header" style={{ background: 'white' }}>Titanium Dioxide</div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header" style={{ background: 'white' }}>Rutile Grade</div>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header" style={{ background: 'white' }}>Anatase Grade</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhitePigment;
