import React from 'react';

const ContactMap = () => {
  const latitude = 25.271080;
  const longitude = 55.306920;

  return (
    <iframe
      title="Map"
      width="100%"
      height="400"
      frameBorder="0"
      style={{ border: 0 }}
      src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14484.915383814487!2d${longitude}!3d${latitude}!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjbCsDQyJzE5LjgiTiA1NcKwMzUnMDMuMCJF!5e0!3m2!1sen!2sus!4v1635007641920!5m2!1sen!2sus`}
      allowFullScreen
    />
  );
};

export default ContactMap;
