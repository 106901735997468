import React from 'react';
import food_colors from '../../assets/images/food_colors.jpg';

function FoodColor() {
  return (
  <section className="section bg-light">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="busi-services-box p-4 bg-white mt-5">
          <div className="busi-services-icon" style={{ width: '150px', height: '160px' }}>
              <i className="text-custom">
                <img src={food_colors} alt="" height="100" />
              </i>
            </div>
            <div className="busi-services-desc">
              <h5 className="mb-4 f-18">Food Colors</h5>
              <p className="text-muted mb-0">Food coloring is used both in commercial food production and in domestic cooking. Food colorants are also used in a variety of non-food applications including cosmetics, pharmaceuticals, home craft projects, and medical devices</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row pt-2">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header" style={{ background: 'white' }}>Blended Food Colors</div>
          </div>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header" style={{ background: 'white' }}>Lake Food Colors</div>
          </div>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header" style={{ background: 'white' }}>Primary Food Colors</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default FoodColor;
